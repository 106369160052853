.AppHeader {
  height: 72px;
  background: #000;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.container {
  max-width: 1338px;
  width: 93%;
  height: 100%;
  margin: 0 auto;
}

.AppHeader a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.signup-link {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 7px 22px;
  font-weight: 600 !important;
}

.drawer-btn svg {
  height: 25px;
  width: 25px;
}
